<template>
  <div class="academic-years">
    <lenon-list-page
      title="Lesson Notes"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-profile-photo="true"
      search-placeholder="Search"
      :hide-table="true"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-button
            v-b-toggle.report
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template slot="table-header">
        <div class="col-12">
          <b-collapse
            id="report"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedTermId"
                  placeholder="Select Term"
                  :options="filteredTerms"
                  label-name="name"
                  value-name="ay_term_id"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="table-content">
        <div class="d-flex flex-row flex-wrap">
          <lesson-note
            v-for="(item,i) in lessonNotes"
            :key="i"
            :item="item"
            @click="downloadLessonNote"
          />
        </div>
      </template>
    </lenon-list-page>
  </div>
</template>

<script>
import {
  BCollapse, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import confirm from '@/lenon/mixins/confirm'
import LessonNote from '@/views/documents/lesson/LessonNote.vue'

export default {
  name: 'Results',
  components: {
    LessonNote,
    LenonSelect,
    LenonListPage,
    LenonButton,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      tableLoading: false,
      selectedClassesForModal: [],
      selectedAcademicYearId: null,
      selectedTermId: null,
    }
  },
  computed: {
    monitorSettings() {
      return +this.selectedAcademicYearId
          * +this.selectedTermId
    },
    previousSetting() {
      return this.$store.getters['timetable/lessonNotesSettings']
    },
    lessonNotes() {
      return this.$store.getters['timetable/lessonNotes']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
  },
  watch: {
    monitorSettings() {
      if (this.selectedTermId && this.selectedAcademicYearId) {
        this.$store.commit('timetable/setLessonNotesSettings', {
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
        })
        this.fetchLessonNotes()
      }
    },
  },
  mounted() {
    this.setSettings()
  },
  methods: {
    downloadLessonNote(item) {
      this.tableLoading = true
      this.$store.dispatch('timetable/downloadLessonNote', item.id).then(data => {
        this.downloadFile(data.path, data.name)
      }).finally(() => {
        this.tableLoading = false
      })
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    setSettings() {
      this.selectedAcademicYearId = this.previousSetting?.selectedAcademicYearId
      this.selectedTermId = this.previousSetting?.selectedTermId
    },
    fetchLessonNotes() {
      this.tableLoading = true
      this.$store.dispatch('timetable/fetchLessonNotes', { term_id: this.selectedTerm.id, ay_id: this.selectedAcademicYearId }).finally(() => {
        this.tableLoading = false
      })
    },
  },
}
</script>
